import React from 'react'
import BoxStaticPageList from '../../../src/common/components/BoxStaticPageList/BoxStaticPageList'
import LayoutLanding from '../../../src/common/components/layouts/layoutLanding/LayoutLanding'

const ImageBateria = `https://${process.env.RODI_AWS_BUCKET}/mota/bateria-mota.jpg`
const ImageAceite = `https://${process.env.RODI_AWS_BUCKET}/mota/mudanca-oleo-mota.jpg`

const ImageDisco = `https://${process.env.RODI_AWS_BUCKET}/mota/travoes-mota.jpg`

const ImageKit = `https://${process.env.RODI_AWS_BUCKET}/mota/kit-transmissao-mota.jpg`

const ImageFiltroAceite = `https://${process.env.RODI_AWS_BUCKET}/mota/filtro-oleo-mota.jpg`
const ImageFiltroAire = `https://${process.env.RODI_AWS_BUCKET}/mota/filtro-ar-mota.jpg`
const ImageEmbrague = `https://${process.env.RODI_AWS_BUCKET}/mota/embraiagem-mota.jpg`

interface Props {
  styles: { [k: string]: string }
}

const sectionsEs = [
  {
    title: 'Serviços para mota Rodi Motor',
    items: [
      {
        title: 'Mudança do óleo',
        description: ``,
        img: ImageAceite,
        route: 'moto.cambio_aceite',
      },
      {
        title: 'Bateria de Mota',
        description: ``,
        img: ImageBateria,
        route: 'moto.baterias',
      },
      {
        title: 'Filtros do óleo',
        description: ``,
        img: ImageFiltroAceite,
        route: 'moto.filtros_aceite',
      },
      {
        title: 'Filtros do ar',
        description: ``,
        img: ImageFiltroAire,
        route: 'moto.filtros_aire',
      },
      {
        title: 'Travões de mota',
        description: ``,
        img: ImageDisco,
        route: 'moto.frenos',
      },
      {
        title: 'Kit de transmissão',
        description: ``,
        img: ImageKit,
        route: 'moto.kit',
      },
      {
        title: 'Embraiagem de mota',
        description: ``,
        img: ImageEmbrague,
        route: 'moto.embrague',
      },
    ],
  },
]

const MotoEs = ({ styles }: Props) => (
  <LayoutLanding
    title="Serviços para a sua mota"
    textRecomendation="Um bom ciclista deve tomar o melhor cuidado possível com a sua mota. Quando se move com ele, a segurança no guiador é uma prioridade. Nas oficinas de Rodi Motor, oferecem-lhe pneus ao melhor preço e aconselham-no sobre como manter a sua mota sempre perfeita."
    textButtonCTA="SOLICITAR MARCAÇÃO PRÉVIA"
    linkButtonCTA="/marcacao-previa/selecao-oficina/"
    descriptionCTA="Precisa que o ajudemos na manutenção da sua Mota? Encontrará tudo o que precisa em Rodi Motor">
    <React.Fragment>
      <p>
        Na Rodi Motor tratamos da manutenção completa da sua motocicleta.
        Oferecemos soluções ao melhor preço. Cuidamos da afinação de todos os
        elementos que compõem a moto: sistema de travagem, kit de transmissão,
        bateria, manutenção, mudança de filtro...
      </p>
      <p>
        Explicamos todos os serviços de motociclismo que encontrará nas oficinas
        da Rodi Motor.
      </p>
      {sectionsEs.map((section) => (
        <BoxStaticPageList
          key={section.title}
          title={section.title}
          items={section.items}
        />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

const MotoContents = ({ ...props }: Props) => <MotoEs {...props} />

export default MotoContents
